<template>
    <Fragment>
        <v-dialog v-model="internalOpen" max-width="800px" persistent>
            <div style="background-color: #FFFFFF; padding: 0 1em 2em; position: relative;">
                <div class="py-4" style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;">
                    <v-btn 
                        style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                        class="px-4 py-4"
                        rounded
                        text
                        @click.stop="cerrarModal"
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                </div>
                <div class="px-1 mt-1">
                    <v-form ref="form" @submit.prevent>
                        <v-textarea
                            label="Comentario"
                            v-model="form.comentario"
                            placeholder="Ingrese un comentario"
                            outlined
                            rows="4"
                            no-resize
                            :error-messages="comentarioErrors"
                            :disabled="creacionAdjunto.isLoading"
                            @change="$v.form.comentario.$touch()"
                            @blur="$v.form.comentario.$touch()"
                        />
                        <v-file-input
                            v-model="form.adjunto"
                            label="Adjunto"
                            placeholder="Seleccione un PDF"
                            outlined
                            light
                            accept="application/pdf"
                            :disabled="creacionAdjunto.isLoading"
                        />
                        <div class="d-flex justify-center flex-wrap flex-md-nowrap" style="gap:16px">
                            <v-btn 
                                color="secondary" 
                                class="flex-grow-1 flex-shrink-1"
                                :disabled="creacionAdjunto.isLoading"
                                @click.stop="cerrarModal"
                            >
                                Cerrar
                            </v-btn>
                            <v-btn 
                                color="primary" 
                                class="flex-grow-1 flex-shrink-1"
                                type="submit"
                                :loading="creacionAdjunto.isLoading"
                                @click.stop="enviarFormulario"
                            >
                                Guardar
                            </v-btn>
                        </div>
                    </v-form>
                </div>
            </div>
        </v-dialog>
        <ConfirmationModalComponent
            :is-open="modalConfirmacionAbierta"
            description="¿Desea continuar con la creación del registro?"
            :is-loading="creacionAdjunto.isLoading"
            @confirm="crearRegistro"
            @cancel="modalConfirmacionAbierta = false"
        />
    </Fragment>
</template>
<script>
import { isNil } from 'lodash';
import { Validator } from '@/utils/form-validation';
import { required } from 'vuelidate/lib/validators';
import { createLoadable, isResponseSuccesful, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import { convertToFormData, removePropsFromObjectIfNil } from '@/utils/data';
import { Fragment } from 'vue-fragment';
import { ConfirmationModalComponent } from '@/components/utils';
import { Observable } from '@/utils/observable.js';

const dataInicialForm = {
    comentario: null,
    adjunto: null,
};

export default {
    name: 'ModalCreacionAdjunto',
    emits: ['on-visibility-change', 'on-nueva-actividad', 'on-ocultamiento-seguimiento'],
    validations() {
        return ({
            form: {
                comentario: { required },
                adjunto: {},
            }
        });
    },
    props: {
        isOpen: { type: Boolean },
        detalles: { type: Object },
    },
    data: () => ({
        internalOpen: false,
        form: dataInicialForm,
        formCache: {},
        creacionAdjunto: createLoadable(null),
        modalConfirmacionAbierta: false,
        idSolicitudCompra: null,
    }),
    computed: {
        comentarioErrors() {
            return new Validator(this.$v.form.comentario).detect().getResult();
        },
    },
    methods: {
        limpiarFormulario() {
            this.form = dataInicialForm;
            this.$v.form.$reset();
            this.$refs.form?.reset();
            this.$v.form.adjunto.$reset();
        },
        cerrarModal() {
            this.internalOpen = false;
            this.limpiarFormulario();
        },
        enviarFormulario() {
            this.$v.$touch();

            if (this.$v.$invalid) return;

            this.confirmarCreacionAdjunto();
        },
        confirmarCreacionAdjunto() {
            this.formCache = removePropsFromObjectIfNil({ ...this.form }, ['adjunto']);
            this.cerrarModal();
            this.modalConfirmacionAbierta = true;
        },
        async crearRegistro() {
            toggleLoadable(this.creacionAdjunto);
            const dataToConvert = this.formCache;
            const formData = convertToFormData(dataToConvert);
            const idProductoSolicitud = this.$route.params.id
            const { data } = await this.services.cmAprobacionSolicitudCompra.crearBitacora(idProductoSolicitud, formData);
            this.modalConfirmacionAbierta = false;
            setLoadableResponse(this.creacionAdjunto, data, { 
                showAlertOnSuccess: true, 
                skipOnSuccess: true, 
            });

            Observable.emit("refresh-history")

            if (isResponseSuccesful(data)) {
                this.cerrarModal();
                return;
            }
        },
    },
    watch: {
        isOpen(value) {
            if (isNil(value))
                return;
            this.internalOpen = value;
        },
        internalOpen(value) {
            this.$emit('on-visibility-change', value);
        },
        tipoRegistro() {
            this.limpiarFormulario();
        },
    },
    components: { Fragment, ConfirmationModalComponent }
}
</script>